import React, { createContext, useState } from "react"

export const PopupContext = createContext(true)

export const PopupProvider = ({ children }) => {
    const [popupBanner, setPopup] = useState(true)
    return (
      <PopupContext.Provider value={{ popupBanner, setPopup }}>
        {children}
      </PopupContext.Provider>
    )
  }
  

