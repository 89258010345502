import React from "react"
import { ApolloProvider } from "@apollo/react-hooks"

import { client } from "./src/apollo/client"
import { StockProvider } from "./src/context/StockContext"
import { PopupProvider } from "./src/context/PopupContext"
import { LangProvider } from "./src/context/LangContext"
import { AuthProvider } from "./src/context/AuthContext"

// export { wrapRootElement } from "./src/apollo/wrap-root-element"
export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <LangProvider>
        <PopupProvider>
          <AuthProvider>
            <StockProvider>{element}</StockProvider>
          </AuthProvider>
        </PopupProvider>
      </LangProvider>
    </ApolloProvider>
  )
}
